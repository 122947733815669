<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current  text-${color}`" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1026_1662)">
      <path
        d="M18.581 2.14073L12.316 0.0517291C12.1109 -0.016592 11.8891 -0.016592 11.684 0.0517291L5.419 2.14073C4.42291 2.47161 3.55642 3.10799 2.94265 3.95944C2.32887 4.81089 1.99904 5.83412 2 6.88373V12.0007C2 19.5637 11.2 23.7407 11.594 23.9147C11.7218 23.9715 11.8601 24.0009 12 24.0009C12.1399 24.0009 12.2782 23.9715 12.406 23.9147C12.8 23.7407 22 19.5637 22 12.0007V6.88373C22.001 5.83412 21.6711 4.81089 21.0574 3.95944C20.4436 3.10799 19.5771 2.47161 18.581 2.14073ZM16.718 9.71773L12.446 13.9897C12.2712 14.1657 12.0632 14.3052 11.834 14.4002C11.6048 14.4951 11.3591 14.5435 11.111 14.5427H11.078C10.8249 14.5389 10.5752 14.4837 10.344 14.3806C10.1128 14.2774 9.90498 14.1285 9.733 13.9427L7.427 11.5427C7.32713 11.4501 7.24725 11.338 7.19226 11.2133C7.13726 11.0887 7.10831 10.9542 7.10718 10.8179C7.10606 10.6817 7.13278 10.5467 7.1857 10.4211C7.23863 10.2956 7.31665 10.1822 7.41497 10.0879C7.51329 9.99363 7.62985 9.92042 7.75748 9.87278C7.88511 9.82515 8.02113 9.80409 8.15719 9.81091C8.29325 9.81773 8.42648 9.85227 8.54872 9.91243C8.67095 9.97258 8.7796 10.0571 8.868 10.1607L11.112 12.5007L15.3 8.30073C15.4886 8.11857 15.7412 8.01778 16.0034 8.02006C16.2656 8.02234 16.5164 8.1275 16.7018 8.31291C16.8872 8.49832 16.9924 8.74913 16.9947 9.01133C16.997 9.27353 16.8962 9.52613 16.714 9.71473L16.718 9.71773Z"
        fill="#00CF53"
      />
    </g>
    <defs>
      <clipPath id="clip0_1026_1662">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
